/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Header from "components/Header/Header.js";
import Parallax from "components/Parallax/Parallax.js";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import presentationStyle from "assets/jss/material-kit-pro-react/views/presentationStyle.js";
import ICSHeaderLinks from "../../components/Header/ICSHeaderLinks";
import {ICSFooter} from "../../components/Footer/ICSFooter";
import Grid from "@material-ui/core/Grid";
import {WPWMauricio} from "../../Tools/WPWMauricio/WPWMauricio";
import {Card, CardHeader} from "@material-ui/core";
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import methode1 from "../WPWMauricioPage/images/methode1.png";
import CardMedia from '@material-ui/core/CardMedia';
import {Helmet} from "react-helmet";
import WPWOblique from "../../Tools/WPWOblique/Images/wpwoblique.png";
import {ResponsiveImage} from "../../components/Image/ResponsiveImage";




const useStyles = makeStyles(presentationStyle);

export default function WPWObliguitePage() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  return (
    <div>

        <Helmet>
            <title>Obliguite des voies accessoires</title>
            <meta name="description" content="Obliguite des voies accessoires" />
            <meta name="keywords" content="voie assessoire, oblique" />
        </Helmet>

        <Header
            brand={"CardioTools"}
            links={<ICSHeaderLinks dropdownHoverColor="info" />}
            fixed
            color="transparent"
            changeColorOnScroll={{
                height: 400,
                color: "info"
            }}
        />
      <Parallax
        image={require("assets/img/slider-bienvenue_bleu.jpg")}
        className={classes.parallax}
        filter="dark"
        style={{height: 200}}
      >
        <div className={classes.container}>
          <GridContainer>
            <GridItem>
              <div className={classes.brand}>
                  <Typography variant={"h2"} component={"h1"}>
                 Obliguite des voies accessoires
                  </Typography>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>


        <GridContainer justify="center" style={{justifyContent : "center", marginTop:"10px", marginBottom: "10px"}}>
            <Grid container xs={9}>
            <Card className={classes.root}>
                <Grid container>
                    <div >
                        <ResponsiveImage
                            src={WPWOblique}
                        />
                    </div>
                    <div >
                        <CardContent className={classes.content} >
                            <Typography color="textSecondary" component={"p"}>
                                The direction of the oblique course of APs around the mitral and tricuspid annulus is illustrated here. D. In a patient with an anteroseptal AP, the AV interval recorded by the HB catheter was significantly longer during RAA pacing than that during CS pacing, indicating that the atrial end of this anteroseptal AP was more septal than the ventricular end. The AV interval recorded by the CS catheter also showed significant changes during differential pacing but these changes are meaningless because the AP is not a left free wall AP. Otomo K et al. Circulation. 2001 Jul 31;104(5):550-6.
                            </Typography>
                        </CardContent>
                    </div>
                </Grid>
            </Card>
            </Grid>

        </GridContainer>

        <ICSFooter/>

    </div>
  );
}
