import {useStyles} from "../../style/useStyle";
import React, {useState} from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import {UnityComponent} from "../../../UnityComponent";
import {NegaVLTransition} from "../Step4/NegaVLTransition";
import {RaVLTransition} from "../Step4/RaVLTransition";

export function NegaVL(){

    const classes = useStyles();

    const [rInaVL, setRInaVL] = useState(null);

    function stepMore(){
        if(rInaVL === "one"){
            return <NegaVLTransition/>
        }else if(rInaVL === "two"){
            return <RaVLTransition/>
        }
    }

    return(

        <Grid container spacing={3} justify={"center"} xs={12} style={{marginBottom:"10px"}}>
            <Grid item xs={12}>
                <Paper className={classes.paper}>
                    <UnityComponent label={"R or r in aVL"} setFunction={(value) => setRInaVL(value.target.value)} value={rInaVL}/>
                </Paper>
            </Grid>

            {stepMore()}
        </Grid>

    );

}