/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Header from "components/Header/Header.js";
import Parallax from "components/Parallax/Parallax.js";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import presentationStyle from "assets/jss/material-kit-pro-react/views/presentationStyle.js";
import ICSHeaderLinks from "../../components/Header/ICSHeaderLinks";
import {ICSFooter} from "../../components/Footer/ICSFooter";
import Grid from "@material-ui/core/Grid";
import {Card} from "@material-ui/core";
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import {Helmet} from "react-helmet";
import {TVScore} from "../../Tools/TVScore/TVScore";


const useStyles = makeStyles(presentationStyle);

export default function TVScorePage() {
    React.useEffect(() => {
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
    });
    const classes = useStyles();
    return (
        <div>
            <Helmet>
                <title>Score de TV</title>
                <meta name="description" content="TV scoreAlgorithme permettant de déterminer si une tachycardie à QRS larges et une tachycardie ventriculaire" />
                <meta name="keywords" content="TV, tachycardie ventriculaire, TV score" />
            </Helmet>

            <Header
                brand={"CardioTools"}
                links={<ICSHeaderLinks dropdownHoverColor="info" />}
                fixed
                color="transparent"
                changeColorOnScroll={{
                    height: 400,
                    color: "info"
                }}
            />
            <Parallax
                image={require("assets/img/slider-bienvenue_bleu.jpg")}
                className={classes.parallax}
                filter="dark"
                style={{height: 200}}
            >
                <div className={classes.container}>
                    <GridContainer>
                        <GridItem>
                            <div className={classes.brand}>
                                <Typography variant={"h2"} component={"h1"}>
                                    TV score
                                </Typography>
                            </div>
                        </GridItem>
                    </GridContainer>
                </div>
            </Parallax>


            <GridContainer justify="center" style={{justifyContent : "center", marginTop:"10px", marginBottom: "10px"}}>


                <Grid container xs={10}>
                    <TVScore/>
                </Grid>



                <Grid container xs={9}>
                    <Card className={classes.root}>
                        <Grid container>


                            <CardContent className={classes.content} >
                                <Typography variant={"h5"} component="h2">
                                    Référence :
                                </Typography>
                                <a href="https://doi.org/10.1016/j.jelectrocard.2017.04.003" target="_blank" rel="noopener noreferrer">
                                    <Typography className={classes.pos} color="textSecondary" component={"p"}>
                                        Ventricular tachycardia score – A novel method for wide QRS complex tachycardia differentiation – Explained
                                    </Typography>
                                    <Typography variant="body2" component="p">
                                        Marek Jastrzebski, Piotr Kukla, Danuta Czarnecka
                                    </Typography>
                                </a>
                            </CardContent>


                        </Grid>
                    </Card>
                </Grid>


            </GridContainer>

            <ICSFooter/>

        </div>
    );
}
