import React from "react";
import esvposition from "./ESV/images/esvposition.jpg"

import Grid from "@material-ui/core/Grid";

import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import presentationStyle from "../assets/jss/material-kit-pro-react/views/presentationStyle";
import Paper from "@material-ui/core/Paper";



const myStyle = {

}


const useStyles = makeStyles({...presentationStyle, ...myStyle});



export function Response({text}){
    const classes = useStyles();

    return(
        <Grid container spacing={3} alignContent={"row"} style={{marginBottom:"10px", margin : "auto"}} xs={12}>

            <Grid item xs={12}>
                <Paper className={classes.paper} style={{fontSize:12}}>
                    <Typography variant={"h4"} component={"p"} style={{padding : "10px", textAlign : "center"}}>{text}</Typography>
                </Paper>
            </Grid>

            <Grid item xs={12}>
                <Paper className={classes.paper} style={{flexDirection:"row"}}>

                    <img style={{width : "100%", maxWidth : "500px", padding:"20px"}} src={esvposition} alt={"localisation des ESV"}/>

                </Paper>
            </Grid>

        </Grid>


    );
}