import React from "react";
import "./ResponsiveImage.css"
import CardMedia from '@material-ui/core/CardMedia';

export function ResponsiveImage( { src, width, height } ) {
    return (
        <div
            style={ {
                width
            } }
            className="responsive-image">
            <div style={ {
                paddingBottom: ( height / width * 100 ) + '%',
            } } />
            <CardMedia
                component="img"
                alt="delta wave measurement method"
                image={src}
                title="Delta wave measurement method"
                />
        </div>
    );
}