import React from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import {useStyles} from "./ESV/style/useStyle";



export function ResponseWPW({text, component, image, referenceDiv}){
    const classes = useStyles();

    return(
        <Grid container spacing={3} alignContent={"row"} style={{marginBottom:"10px"}} xs={12}>
            <Grid item xs={12}>
                <Paper className={classes.paper} style={{flexDirection:"row"}}>
                    <div style={{textAlign : "center", marginBottom:"10px", flex:1}}><strong>{component ? component : text}</strong></div>
                    {image ? <img style={{flex:1, width : "100%"}} src={image.src} alt={image.alt}/> : null}
                </Paper>
            </Grid>
            {referenceDiv ?
                <Grid item xs={12}>
                    <Paper className={classes.paper} style={{fontSize:12}}>
                        {referenceDiv}
                    </Paper>
                </Grid>
                 : null
                }

        </Grid>
    );
}