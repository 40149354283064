import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import React from "react";

export function UnityComponent({label, setFunction, value}){

    return (
        <FormControl component="fieldset">
            <FormLabel component="legend" style={{paddingBottom : "20px"}}>{label}</FormLabel>
            <RadioGroup row aria-label={label} name={label} value={value} onChange={setFunction} >
                <FormControlLabel
                    value={null}
                    control={<Radio color="primary" />}
                    label="SP"
                    labelPlacement="top"
                />
                <FormControlLabel
                    value={"one"}
                    control={<Radio color="primary" />}
                    label="YES"
                    labelPlacement="top"
                />
                <FormControlLabel
                    value={'two'}
                    control={<Radio color="primary" />}
                    label="NO"
                    labelPlacement="top"
                />
            </RadioGroup>
        </FormControl>
    )
}