import React, {useEffect, useState} from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import {UnityComponent} from "../UnityComponent";
import {AxeInfieur} from "./AxeInferieur/AxeInferieur";
import {useStyles} from "./style/useStyle";
import {AxeSuperieur} from "./AxeSuperieur/AxeSuperieur";
import {Response} from "../Response";



export function AxeVertical(){

  const classes = useStyles();

  const [supAxe, setSupAxe] = useState(null);

  const [D2, setD2] = useState(null);
  const [D3, setD3] = useState(null);


  useEffect(() => {
    if(D2 === "one" && D3 === "one"){
      setSupAxe("inferieur");
    }else if(D2 === "two" && D3 === "two"){
      setSupAxe("superieur");
    }else if(D2 === "one" && D3 === "two"){
      setSupAxe("D2PD3N");
    }else if(D2 === "two" && D3 === "one"){
      setSupAxe("D2NP3P");
    }else{
      setSupAxe(null);
    }
  }, [D2, D3])


  function renderStepTwo(){
    if(supAxe){
      switch (supAxe) {
        case "inferieur":
          return(<AxeInfieur/>)
        case "superieur":
          return (<AxeSuperieur/>)
        case "D2PD3N" :
          return (<Response text={"Lateral TV (rS V1, V4-V5 transition), MB (QS or rS V1, V5-V6 transition), Parahisian (QS V1, V2-V3 transistion, narrow QRS)"}/>)
        case "D2NP3P" :
          return (<Response text={"Lateral MV (R/S V6 >1), APM (R/S V6 <1)"}/>)
        default:
          return null
      }
    }else{
      return null
    }

  }

  return(
      <Grid container spacing={3} style={{marginTop:"10px", marginBottom:"10px"}} justify={"center"}>

      <Grid container spacing={3} alignContent={"row"} style={{marginBottom:"10px"}} xs={12}>
        <Grid item xs={6}>
          <Paper className={classes.paper}>
            <UnityComponent label={"D2 positif"} setFunction={(value) => setD2(value.target.value)} value={D2}/>
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper className={classes.paper}>
            <UnityComponent label={"D3 positif"} setFunction={(value) => setD3(value.target.value)} value={D3}/>
          </Paper>
        </Grid>
      </Grid>

        {renderStepTwo()}

      </Grid>

  );



}

