/* eslint-disable */
import React from "react";
import { Link } from "react-router-dom";
import styles from "assets/jss/material-kit-pro-react/components/headerLinksStyle.js";

import ListItem from "@material-ui/core/ListItem";
import {makeStyles} from "@material-ui/core/styles";
import CustomDropdown from "../CustomDropdown/CustomDropdown";
import {Typography} from "@material-ui/core";
import {Link as UiLink} from "@material-ui/core"


const useStyles = makeStyles(styles);

const exempleMenu = [
    {
        titleButton : "Electrophysiologie",
        links : [
            {
                titleLink : "Localisation ESV",
                urlLink : "/esv",
                search : null
            },
            {
                titleLink : "Localisation WPW",
                urlLink : "/complexwpw",
                urlSearch : null
            },
        ],
        search : null
    },
];


const menus = [
    {
        titleLink : "Localisation ESV",
        urlLink : "/esv",
        search : null
    },
    {
        titleButton : "Localisation WPW",
        links : [
            {
                titleLink : "Algorithme simple",
                urlLink : "/simplewpw",
                search : null
            },
            {
                titleLink : "Algorithme complexe",
                urlLink : "/complexwpw",
                urlSearch : null
            },
            {
                titleLink : "Obliguité des WPW",
                urlLink : "/wpwoblique",
                urlSearch : null
            }
        ],
    },
    {
        titleLink : "TV score",
        urlLink : "/tvscore",
        search : null
    },
    {
        titleButton : "Echocardiographie",
        links : [
            {
                titleLink : "Hémodynamique (débit et résistances)",
                href : "https://www.easyecho.fr/outils",
                search : null
            },
            {
                titleLink : "PISA",
                href : "https://www.easyecho.fr/outils",
                urlSearch : null
            },
            {
                titleLink : "Taille aorte",
                href : "https://www.easyecho.fr/outils",
                urlSearch : null
            }
        ],
        search : null
    },
    ];

export function IcsHeaderItem(props){

    const classes = useStyles();


    return menus.map((menu) =>
            <ListItem className={classes.listItem}>
                {menu.hasOwnProperty("links") && Array.isArray(menu.links) ? <CustomDropdown
                    noLiPadding
                    navDropdown
                    hoverColor={props.dropdownHoverColor}
                    buttonText={menu.titleButton}
                    buttonProps={{
                        className: classes.navLink,
                        color: "transparent"
                    }}
                    buttonIcon={menu.iconButton}
                    dropdownList={
                        menu.links.map((e) => {
                                if (e.hasOwnProperty("href")) {
                                    return (<Typography><a href={e.href}>{e.titleLink}</a></Typography>)
                                } else {
                                    return (<Link to={{
                                        pathname: e.urlLink,
                                        search: e.urlSearch
                                    }} className={classes.dropdownLink}>
                                        {e.titleLink}
                                    </Link>)
                                }
                            }
                        )
                    }
                />:
                        <Link to={{
                            pathname: menu.urlLink,
                            search: menu.urlSearch
                        }}
                              className={classes.dropdownLink}>
                        {menu.titleLink}
                        </Link>
                }
            </ListItem>

    )
}