/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Header from "components/Header/Header.js";
import Parallax from "components/Parallax/Parallax.js";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import presentationStyle from "assets/jss/material-kit-pro-react/views/presentationStyle.js";
import ICSHeaderLinks from "../../components/Header/ICSHeaderLinks";
import {ICSFooter} from "../../components/Footer/ICSFooter";
import Grid from "@material-ui/core/Grid";
import {WPWMauricio} from "../../Tools/WPWMauricio/WPWMauricio";
import {Card, CardHeader} from "@material-ui/core";
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import methode1 from "./images/methode1.png";
import CardMedia from '@material-ui/core/CardMedia';
import {Helmet} from "react-helmet";




const useStyles = makeStyles(presentationStyle);

export default function WPWMauricioPage() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  return (
    <div>

        <Helmet>
            <title>Localisation WPW (by MAURICIO et al)</title>
            <meta name="description" content="Outils permettant de localiser une voie accessoire à partir de l'ECG. Algorithme basé sur l'article de MAURICIO S et al." />
            <meta name="keywords" content="WPW localisation, WPW, wolf-parkinson-white, voie assessoire" />
        </Helmet>

        <Header
            brand={"CardioTools"}
            links={<ICSHeaderLinks dropdownHoverColor="info" />}
            fixed
            color="transparent"
            changeColorOnScroll={{
                height: 400,
                color: "info"
            }}
        />
      <Parallax
        image={require("assets/img/slider-bienvenue_bleu.jpg")}
        className={classes.parallax}
        filter="dark"
        style={{height: 200}}
      >
        <div className={classes.container}>
          <GridContainer>
            <GridItem>
              <div className={classes.brand}>
                  <Typography variant={"h2"} component={"h1"}>
                  WPW Localisation
                  </Typography>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>


        <GridContainer justify="center" style={{justifyContent : "center", marginTop:"10px", marginBottom: "10px"}}>
            <Grid container xs={9}>
            <Card className={classes.root}>
                <CardHeader title={"Method"}/>
                <Grid container>
                    <div style={{flex : 3}}>
                        <CardMedia
                            component="img"
                            alt="delta wave measurement method"
                            image={methode1}
                            title="Delta wave measurement method"
                        />
                    </div>
                    <div style={{flex : 7}}>
                        <CardContent className={classes.content} >
                            <Typography color="textSecondary" component={"p"}>
                                Delta wave polarity was determined by examining the initial 20 msec after earliest delta wave onset in the limb leads as well as precordiut leuih. (A) ECG leads I, II and III of a patient with an accessory pathway located at the posteroseptal tricuspid annulus region. Note that it is possible to determine delta wave polarity in all three leads at approximately 20 msec after the onset of delta wave. (B) Determination of delta wave polarity (using the initial 20 msec) in the event of changes within 40 msec.
                            </Typography>
                        </CardContent>
                    </div>
                </Grid>
            </Card>
            </Grid>

            <Grid container xs={10}>
                <WPWMauricio/>
            </Grid>



            <Grid container xs={9}>
                <Card className={classes.root}>
                    <Grid container>


                        <CardContent className={classes.content} >
                            <Typography variant={"h5"} component="h2">
                                Référence :
                            </Typography>
                            <a href="https://onlinelibrary.wiley.com/doi/abs/10.1111/j.1540-8167.1998.tb00861.x" target="_blank" rel="noopener noreferrer">
                            <Typography className={classes.pos} color="textSecondary" component={"p"}>
                                Development and validation of an ECG algorithm for identifying accessory pathway ablation site in Wolff-Parkinson-White syndrome
                            </Typography>
                            <Typography variant="body2" component="p">
                                MAURICIO S. ARRUDA M.D.  JAMES H. McCLELLAND M.D.  XANZHUNG WANG M.D.  KAREN J. BECKMAN M.D.  LAWRENCE E. WIDMAN M.D., Ph.D.  MARIO D. GONZALEZ M.D.  HIROSHI NAKAGAWA M.D., Ph.D.  RALPH LAZZARA M.D.  WARREN M. JACKMAN M.D.
                            </Typography>
                            </a>
                        </CardContent>


                    </Grid>
                </Card>
            </Grid>


        </GridContainer>

        <ICSFooter/>

    </div>
  );
}
