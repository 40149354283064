import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {ResponsiveImage} from "../../components/Image/ResponsiveImage";
import RinV1 from "./images/REnV1.png"
import RenV1V2 from "./images/REnV1V2.png"
import RWPT from "./images/RWPT.png"
import SEnV1 from "./images/SEnV1.png"
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import Alert from '@material-ui/lab/Alert';


const useStyles = makeStyles({
    root: {
        width: '100%',
    },
});

export function TVScore() {
    const classes = useStyles();

    const [dissociation, setDissociation] = useState(0);
    const [rLarge, setRLarge] = useState(0);
    const [rHaute, setRHaute] = useState(0);
    const [notchS, setNotchS] = useState(0);
    const [raVL, setRaVL] = useState(0);
    const [rwpt, setRwpt] = useState(0);
    const [absRS, setAbsRS] = useState(0);

    const [score, setScore] = useState(0);


    function handleChangeScore(event, score, setName){
        if(event.target.checked){
            setName(score);
        }else{
            setName(0);
        }
    }


    const senSpe = {
        0 : {
            "sensibilite" : 1,
            "specificite" : 0
        },
        1 : {
            "sensibilite" : 0.9375,
            "specificite" : 0.635036496
        },
        2 : {
            "sensibilite" : 0.7734375,
            "specificite" : 0.890510949
        },
        3 : {
            "sensibilite" : 0.57421875,
            "specificite" : 0.996350365
        },
        4 : {
            "sensibilite" : 0.326171875,
            "specificite" : 1
        },
        5 : {
            "sensibilite" : 0.13671875,
            "specificite" : 1
        }
    }


    function probabiliteTV(){
        if(score>3){
            return "certain"
        }
        else if(score === 3){
            return "quasi certain"
        }else if(score === 2){
            return "probable"
        }else if( score === 1){
            return "possible"
        }else{
            return "très peu probable"
        }
    }

    function couleurAlert(){
        if(score>3){
            return "error"
        }
        else if(score === 3){
            return "error"
        }else if(score === 2){
            return "warning"
        }else if( score === 1){
            return "warning"
        }else{
            return "info"
        }
    }

    function calculSenSpe(){
        if(score <= 5){
            return senSpe[score]
        }else if(score > 5){
            return senSpe[5]
        }
    }

    useEffect(() => {
        setScore(dissociation + rLarge + rHaute + notchS + raVL + rwpt + absRS)
    }, [dissociation, rLarge, rHaute, notchS, raVL, rwpt, absRS])


    return (
        <div className={classes.root}>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-label="Expand"
                    aria-controls="additional-actions1-content"
                    id="additional-actions1-header"
                >
                    <FormControlLabel
                        aria-label="Acknowledge"
                        onClick={(event) => event.stopPropagation()}
                        onFocus={(event) => event.stopPropagation()}
                        control={<Checkbox checked={dissociation} onChange={(event)=> handleChangeScore(event, 2, setDissociation)}/>}
                        label="Dissociation AV ou Complexe de fusion ou complexe de capture?"
                    />
                </AccordionSummary>
                <AccordionDetails>
                    <a href={"https://www.e-cardiogram.com/dissociation-atrio-ventriculaire/"} target="_blank" rel="noopener noreferrer">
                        <Typography>Cliquez pour avoir plus d'informations</Typography>
                    </a>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-label="Expand"
                    aria-controls="additional-actions2-content"
                    id="additional-actions2-header"
                >
                    <FormControlLabel
                        aria-label="Acknowledge"
                        onClick={(event) => event.stopPropagation()}
                        onFocus={(event) => event.stopPropagation()}
                        control={<Checkbox checked={rLarge} onChange={(event)=> handleChangeScore(event, 1, setRLarge)}/>}
                        label="En V1 ou V2 si le QRS est négatif : r > 40 ms avec r<S ?"
                    />
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>Exemples</Typography>
                    <ResponsiveImage
                        src={RenV1V2}
                    />
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-label="Expand"
                    aria-controls="additional-actions3-content"
                    id="additional-actions3-header"
                >
                    <FormControlLabel
                        aria-label="Acknowledge"
                        onClick={(event) => event.stopPropagation()}
                        onFocus={(event) => event.stopPropagation()}
                        control={<Checkbox checked={rHaute} onChange={(event)=> handleChangeScore(event, 1, setRHaute)}/>}
                        label="Le QRS en V1 débute par une onde R prédominante"
                    />
                </AccordionSummary>
                <AccordionDetails >
                    <GridContainer>

                    <GridItem flex={1}>
                        <ul>
                            <li>Inclus des complexes R, R>S, Rsr’</li>
                            <li>Attention les complexes monophasiques R avec un notch dans la moitier inférieure de la partie ascendante de l’onde R ne sont pas inclus car considérés comme rsR’.</li>
                            <li>Exclusion des complexes qR</li>
                        </ul>
                    </GridItem>

                    <GridItem flex={1}>
                        <Typography>Exemples</Typography>
                        <ResponsiveImage
                            src={RinV1}
                        />
                    </GridItem>
                    </GridContainer>

                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-label="Expand"
                    aria-controls="additional-actions3-content"
                    id="additional-actions3-header"
                >
                    <FormControlLabel
                        aria-label="Acknowledge"
                        onClick={(event) => event.stopPropagation()}
                        onFocus={(event) => event.stopPropagation()}
                        control={<Checkbox checked={notchS} onChange={(event)=> handleChangeScore(event, 1, setNotchS)}/>}
                        label="Notch de la partie descendante de l’onde S en V1"
                    />
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>Exemples</Typography>
                    <ResponsiveImage
                        src={SEnV1}
                    />
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-label="Expand"
                    aria-controls="additional-actions3-content"
                    id="additional-actions3-header"
                >
                    <FormControlLabel
                        aria-label="Acknowledge"
                        onClick={(event) => event.stopPropagation()}
                        onFocus={(event) => event.stopPropagation()}
                        control={<Checkbox checked={raVL} onChange={(event)=> handleChangeScore(event, 1, setRaVL)}/>}
                        label="Initial R in aVR : inclus R / RS avec R>S / Rsr’"
                    />

                </AccordionSummary>
                <AccordionDetails>
                    <Typography color="textSecondary">
                        inclus R / RS avec R>S / Rsr’
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-label="Expand"
                    aria-controls="additional-actions3-content"
                    id="additional-actions3-header"
                >
                    <FormControlLabel
                        aria-label="Acknowledge"
                        onClick={(event) => event.stopPropagation()}
                        onFocus={(event) => event.stopPropagation()}
                        control={<Checkbox checked={rwpt} onChange={(event)=> handleChangeScore(event, 1, setRwpt)}/>}
                        label="RWPT mesuré en DII ≥ 50ms"
                    />
                </AccordionSummary>
                <AccordionDetails>
                    <GridContainer>

                        <GridItem flex={1}>
                            <Typography color="textSecondary">
                                Méthode de mesure : RWPT est défini par la durée entre le début du QRS et le premier changement de dépolarisation, sans tenir compte du sens du changement.
                            </Typography>
                        </GridItem>
                        <GridItem flex={1}>
                            <Typography>Exemples</Typography>
                            <ResponsiveImage
                                src={RWPT}
                            />
                        </GridItem>

                        <GridItem flex={1}>
                            <a href={"https://www.e-cardiogram.com/indice-de-tv-pava-qrs-dii/"} target="_blank" rel="noopener noreferrer">
                                <Typography>Pour plus d'informations</Typography>
                            </a>
                        </GridItem>
                    </GridContainer>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    aria-label="Expand"
                    aria-controls="additional-actions3-content"
                    id="additional-actions3-header"
                >
                    <FormControlLabel
                        aria-label="Acknowledge"
                        onClick={(event) => event.stopPropagation()}
                        onFocus={(event) => event.stopPropagation()}
                        control={<Checkbox checked={absRS} onChange={(event)=> handleChangeScore(event, 1, setAbsRS)}/>}
                        label="Pas de complexe RS dans les dérivations V1-V6"
                    />
                </AccordionSummary>
            </Accordion>

            {
                score>0 ?
                <Alert severity={couleurAlert()}
                       style={{
                           marginTop : "25px",
                           marginBottom : "25px"
                       }}
                >
                    <Typography>Le score est de {score}/8. La probabilité de TV est {probabiliteTV()}.</Typography>
                    <Typography>Sensibilité du score obtenu : {parseInt(calculSenSpe().sensibilite*100)}%</Typography>
                    <Typography>Spécificité du score obtenu : {parseInt(calculSenSpe().specificite*100)}%</Typography>

                </Alert> :
                    <Alert severity={couleurAlert()}
                           style={{
                               marginTop : "25px",
                               marginBottom : "25px"
                           }}
                    >
                        <Typography> Le score est de {score}/8. La probabilité de TV est {probabiliteTV()}.</Typography>
                    </Alert>
            }

        </div>
    );
}

