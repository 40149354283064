import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Radio from "@material-ui/core/Radio";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import {TextField} from "@material-ui/core";

import React, {useEffect, useState} from "react";
import {useStyles} from "../../style/useStyle";
import {Response} from "../../../Response";
import {TwoChoices} from "../../../TwoChoices";
import v2transitionRatio from "../../images/V2TransitionRatio.png"
import Typography from "@material-ui/core/Typography";


function V2TransitionRation({title}){

    const classes = useStyles();

    const [rSinus, setRSinus] = useState(null);
    const [sSinus, setSSinus] = useState(null);
    const [sPVC, setSPVC] = useState(null);
    const [rPVC, setRPVC] = useState(null);
    const [ratio, setRatio] = useState(null)
    const [LVOT, setLVOT] = useState(null)


    useEffect(() => {
        if(rSinus && sSinus && sPVC && rPVC){
            var ratioSinus = parseInt(rSinus)/(parseInt(rSinus)+parseInt(sSinus));
            var rationPVC = parseInt(rPVC)/(parseInt(rPVC)+parseInt(sPVC));

            var inRatio = parseFloat(rationPVC)/parseFloat(ratioSinus);

            if(ratioSinus === 0 && ratioSinus === 0){
                inRatio = 0;
            }
            setRatio(inRatio);
        }else{
            setRatio(null)
        }
    }, [rSinus, sSinus, sPVC, rPVC])

    useEffect(() => {

        if(ratio >= 0.6){
            setLVOT(true)
        }else if(ratio < 0.6){
            setLVOT(false)
        }else{
            setLVOT(null)
        }

    }, [ratio])


    function nextStep(){
        if(LVOT === true){
            return <Response text={"LVOT"}/>
        }else if(LVOT === false){
            return <Response text={"RVOT"}/>
        }else if(LVOT === null){
            return null
        }
        else{
            return null;
        }
    }

    return(
        <Grid container spacing={3} style={{marginTop:"10px", marginBottom:"10px"}} justify={"center"} xs={12}>

            <Grid container spacing={3} alignContent={"row"} style={{marginBottom:"10px"}} xs={12}>
                <Grid item xs={12}>
                    <Paper className={classes.paper} >
                        <h2>Method of measurment</h2>
                        <img src={v2transitionRatio} style={{width: "50%"}} alt={"method of measurment od transition ratio"}/>
                        <Typography variant="body2" component="p">
                            {"Transition Ratio = B/(C+B)*(F+E)/E. If the transition ratio is 􏰂< 0.6, then RVOT origin is likely. If the transition ratio is 􏰇≥ 0.6, then LVOT origin is likely (sensitivity 95%, specificity 100%)."}
                        </Typography>
                    </Paper>
                </Grid>
            </Grid>


            <Grid container spacing={3} alignContent={"row"} style={{marginBottom:"10px"}} xs={12}>
                <Grid item xs={12}>
                    <Paper className={classes.paper} >
                        <h2>Amplitude of : </h2>
                        <Grid container style={{justifyContent : "space-around"}}>
                            <Grid item><TextField label={"R in Sinus Rhythm (E)"} onChange={(value) => setRSinus(value.target.value)} value={rSinus}/></Grid>
                            <Grid item><TextField label={"S in Sinus Rhythm (F)"} onChange={(value) => setSSinus(value.target.value)} value={sSinus}/></Grid>
                            <Grid item><TextField label={"R in PVC/VT (B)"} onChange={(value) => setRPVC(value.target.value)} value={rPVC}/></Grid>
                            <Grid item><TextField label={"S in PVC/VT (C)"} onChange={(value) => setSPVC(value.target.value)} value={sPVC}/></Grid>
                        </Grid>
                        <br/>
                        <Typography variant="body2" component="p">
                            Transition Ratio = {parseFloat(parseInt(ratio*10)/10)}
                        </Typography>
                    </Paper>
                </Grid>
            </Grid>

            {nextStep()}

        </Grid>
    )
}



function TransitionV3(){
    return <TwoChoices
        question={"PVC/VT R/S transition later than sinus rythm ?"}
        reponseComponentTrue={<Response text={"Anterior RVOT"}/>}
        reponseComponentFalse={<V2TransitionRation/>}
    />
}

export function Transition(){

    const [transition, setTransition] = useState(null);

    const classes = useStyles();


    function thisResponse(){
        if(transition === "one"){
            return <TransitionV3/>
        }else if(transition === "two"){
            return <Response text={"RVOT"}/>
        }
        else if(transition === "three"){
            return <Response text={"Left coronary cusp (notched V1), LV summit (pseudo delta)"}/>
        }
        else if(transition === "four"){
            return <Response text={"Left coronary cusp (notched V1), LV summit (pseudo delta), Aortomitral continuity (qR in V1), top Mitral valve (R or Rsr' in V1), Anterior Mitral Pillar (R, Rsr' or qR in V1), LAF (rsR' in V1 narrow QRS)"}/>
        }
    }

    return(
        <Grid container spacing={3} justify={"center"} xs={12} style={{marginBottom:"10px"}}>
            <Grid item xs={12}>
                <Paper className={classes.paper}>
                    <FormControl component="fieldset">
                        <RadioGroup row value={transition} onChange={(value) => setTransition(value.target.value)} >
                            <FormControlLabel
                                value={null}
                                control={<Radio color="primary" />}
                                label="SP"
                                labelPlacement="top"
                            />
                            <FormControlLabel
                                value={"four"}
                                control={<Radio color="primary" />}
                                label="Transition V1 (RBBB)"
                                labelPlacement="top"
                            />
                            <FormControlLabel
                                value={"three"}
                                control={<Radio color="primary" />}
                                label="Transition = V2"
                                labelPlacement="top"
                            />
                            <FormControlLabel
                                value={"one"}
                                control={<Radio color="primary" />}
                                label="Transition = V3"
                                labelPlacement="top"
                            />
                            <FormControlLabel
                                value={"two"}
                                control={<Radio color="primary" />}
                                label="Transition ≥ V4"
                                labelPlacement="top"
                            />


                        </RadioGroup>
                    </FormControl>
                </Paper>
            </Grid>

            {thisResponse()}
        </Grid>

    )
}

