import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import React, {useState} from "react";
import {useStyles} from "../../style/useStyle";
import {Response} from "../../../Response";


export function LBBB(){

    const [transition, setTransition] = useState(null);

    const classes = useStyles();


    function thisResponse(){
        if(transition === "one"){
            return <Response text={"TV free wall, MB"}/>
        }else if(transition === "two"){
            return <Response text={"TV septum, crux (QS inferior lead, pseudo delta)"}/>
        }

    }

    return(
        <Grid container spacing={3} xs={12} style={{marginBottom:"10px"}}>
            <Grid item xs={12}>
                <Paper className={classes.paper}>
                    <FormControl component="fieldset">
                        <RadioGroup row value={transition} onChange={(value) => setTransition(value.target.value)} >
                            <FormControlLabel
                                value={null}
                                control={<Radio color="primary" />}
                                label="SP"
                                labelPlacement="top"
                            />
                            <FormControlLabel
                                value={"one"}
                                control={<Radio color="primary" />}
                                label="Transition ≥ V4 "
                                labelPlacement="top"
                            />
                            <FormControlLabel
                                value={"two"}
                                control={<Radio color="primary" />}
                                label="Transition ≤ V3"
                                labelPlacement="top"
                            />
                        </RadioGroup>
                    </FormControl>
                </Paper>
            </Grid>

            {thisResponse()}
        </Grid>

    )
}

