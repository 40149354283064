/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Header from "components/Header/Header.js";
import Parallax from "components/Parallax/Parallax.js";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// sections for this page


import presentationStyle from "assets/jss/material-kit-pro-react/views/presentationStyle.js";
import ICSHeaderLinks from "../../components/Header/ICSHeaderLinks";
import {ICSFooter} from "../../components/Footer/ICSFooter";
import {Card, CardHeader} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import {Helmet} from "react-helmet";




const myStyle = {
    root : {
        flex : 12,
    }
}

const useStyles = makeStyles({...presentationStyle, ...myStyle});



export default function PresentationPage() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  return (
    <div>
        <Helmet>
            <title>CardioTools - Page principale</title>
            <meta name="description" content="description des objectifs de cardiotools" />
            <meta name="keywords" content="cardiologue, outils cardiologiques, algorithmes cardiologiques" />
        </Helmet>


        <Header
            brand={"CardioTools"}
            links={<ICSHeaderLinks dropdownHoverColor="info" />}
            fixed
            color="transparent"
            changeColorOnScroll={{
                height: 400,
                color: "info"
            }}
        />
      <Parallax
        image={require("assets/img/slider-bienvenue_bleu.jpg")}
        className={classes.parallax}
        style={{height:400}}
        filter="dark"
      >
        <div className={classes.container}>
          <GridContainer>
            <GridItem>
                <div className={classes.brand}>
                    <Typography variant={"h2"} component={"p"}>
                        CardioTools
                    </Typography>

                    <Typography variant={"h5"} component={"p"}>
                        Met à disposition des cardiologues les outils de leur quotidien.
                    </Typography>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>




        <GridContainer justify="center" style={{justifyContent : "center", marginTop:"10px", marginBottom: "10px"}}>
            <Grid container xs={10}>
                <Card className={classes.root}>
                    <Grid container>
                            <CardContent className={classes.content} >
                                <Typography variant={"h4"} component={"h1"}>
                                    Présentation du site
                                </Typography>
                                <br/>
                                <Typography variant={"body1"} component={"p"}>
                                    L'objectif de ce site est de numériser certains algorithmes et outils utiles à la pratique des cardiologues.
                                </Typography>
                                <br/>
                                <Typography variant={"body1"} component={"p"}>
                                    Ce site n'est qu'à ses débuts. Nous avons besoin de vous ! A vous de nous dire quels outils, quels algorithmes vous souhaitez voir numérisés afin de faciliter votre pratique quotidienne.
                                </Typography>
                                <br/>
                                <Typography variant={"body1"} component={"p"}>
                                    N'hésitez pas à nous envoyer un mail avec vos demandes à l'adresse suivante <a href="mailto:cardiotools@gmail.com">cardiotools@gmail.com</a>
                                </Typography>

                            </CardContent>

                    </Grid>
                </Card>
            </Grid>

        </GridContainer>

        <GridContainer justify="center" style={{justifyContent : "center", marginTop:"10px", marginBottom: "10px"}}>
            <Grid container xs={10}>
                <Card className={classes.root}>
                    <Grid container>

                        <CardContent className={classes.content} >
                            <Typography variant={"h4"} component={"h2"}>
                                Créer une icone vers CardioTools sur votre smartphone
                            </Typography>
                            <br/>
                            <Typography variant={"h5"} component={"h3"}>
                                Pour Iphone
                            </Typography>
                            <br/>
                            <ol>
                                <Typography component={"li"}>Lancez Safari</Typography>
                                <Typography component={"li"}>Allez sur la page de cardiotools.fr</Typography>
                                <Typography component={"li"}>Appuyez sur l’icône de partage en bas de votre écran</Typography>
                                <Typography component={"li"}>Choisissez "Sur l’écran d’accueil"</Typography>
                                <Typography component={"li"}>Choisissez le nom qui apparaîtra sous l’icone. Validez en appuyant sur "Ajouter"</Typography>
                            </ol>
                            <br/>
                            <a href={"https://www.itvisions.fr/raccourci-web-android/"} target="_blank" rel="noopener noreferrer">
                                <Typography variant={"h5"} component={"h3"}>
                                Pour Android
                                </Typography>
                            </a>



                        </CardContent>
                    </Grid>
                </Card>
            </Grid>

        </GridContainer>

        <ICSFooter/>

    </div>
  );
}
