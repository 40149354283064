import React from 'react';
import {TwoChoices} from "../TwoChoices";
import {ResponseWPW} from "../ResponseWPW";
import septalimage from "./septal.png"
import epicadialImage from "./SubEpicardial.png"
import leftWPWImage from "./Left free wall.png"
import rightWPWImage from "./RightFreeWall.png"


function AVFOne(){
    return <TwoChoices
        //title={"Left free wall"}
        question={"aVF positive"}
        reponseComponentTrue={<ResponseWPW text={"Left Lateral or Left anterolateral"} image={{src: leftWPWImage, alt:"left free wall accessory pathways"}}/> }
        reponseComponentFalse={<ResponseWPW text={"Left posterior or Left posterolateral"} image={{src: leftWPWImage, alt:"left free wall accessory pathways"}}/> }
    />
}

function D3(){
    return <TwoChoices
        //title={"Septal"}
        question={"III R ≥ S"}
        reponseComponentTrue={<ResponseWPW text={"Anteroseptal"} image={{src: septalimage, alt:"septal accessory pathways"}}/> }
        reponseComponentFalse={<ResponseWPW text={"Midseptal"} image={{src: septalimage, alt:"septal accessory pathways"}}/> }
    />
}

function AVFTwoIso(){
    return <TwoChoices
        //title={"Septal"}
        question={"aVF ±"}
        reponseComponentTrue={<ResponseWPW text={"Posteroseptal tricuspid or mitral annulus"} image={{src: rightWPWImage, alt:"right free wall accessory pathways"}}/> }
        reponseComponentFalse={<D3/>}
    />
}

function AVFTwoNeg(){
    return <TwoChoices
        //title={"Septal"}
        question={"aVF negative"}
        reponseComponentTrue={<ResponseWPW text={"Posteroseptal tricuspid annulus"} image={{src: rightWPWImage, alt:"right free wall accessory pathways"}}/> }
        reponseComponentFalse={<AVFTwoIso/>}
    />
}



function D2pos(){
    return <TwoChoices
        //title={"Right free wall"}
        question={"II positive"}
        reponseComponentTrue={<ResponseWPW text={"Right lateral"} image={{src: rightWPWImage, alt:"right free wall accessory pathways"}}/>}
        reponseComponentFalse={<ResponseWPW text={"Right posterior or right posterolateral"} image={{src: rightWPWImage, alt:"right free wall accessory pathways"}}/>}
    />
}


function AVFPos(){
    return <TwoChoices
       // title={"Right free wall"}
        question={"aVF positive"}
        reponseComponentTrue={<ResponseWPW text={"Right anterior or right anterolateral"} image={{src: rightWPWImage, alt:"right free wall accessory pathways"}}/>}
        reponseComponentFalse={<D2pos/>}
    />
}

function V1Postitive(){
    return <TwoChoices
        question={"V1 positive"}
        reponseComponentTrue={<AVFPos/>}
        reponseComponentFalse={<AVFTwoNeg/>}
    />
}

function D2neg(){
    return <TwoChoices
        question={"D2 negative"}
        reponseComponentTrue={<ResponseWPW text={"Sub-epicardial"} image={{src: epicadialImage, alt:"Sub-epicardial accessory pathways"}}/> }
        reponseComponentFalse={<V1Postitive/>}
    />
}


export function WPWMauricio(){

    return <TwoChoices
        question={"I ± or negative OR V1 R ≥ S"}
        reponseComponentTrue={<AVFOne/>}
        reponseComponentFalse={<D2neg/>}
    />
}




