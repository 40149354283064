import React, {useState} from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import {UnityComponent} from "../../UnityComponent";
import {useStyles} from "../style/useStyle";
import {NegaVL} from "./Step3/NegaVL";
import {Transition} from "./Step3/Transition";



export function AxeInfieur(){

    const classes = useStyles();

    const [D1, setD1] = useState(null);

    function stepMore(){
        if(D1 === "one"){
            return <NegaVL/>
        }else if(D1 === "two"){
            return <Transition/>
        }
    }

    return(

        <Grid container spacing={3} justify={"center"} xs={12} style={{marginBottom:"10px"}}>
            <Grid item xs={12}>
                <Paper className={classes.paper}>
                    <UnityComponent label={"D1 positif"} setFunction={(value) => setD1(value.target.value)} value={D1}/>
                </Paper>
            </Grid>
            {stepMore()}
        </Grid>


    );

}

