/*!

=========================================================
* Material Kit PRO React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { Route, Switch } from "react-router";
import { BrowserRouter } from 'react-router-dom';
import Analytics from 'react-router-ga';


import "assets/scss/material-kit-pro-react.scss?v=1.8.0";

import "../node_modules/video-react/dist/video-react.css";

// pages for this product



import ErrorPage from "views/ErrorPage/ErrorPage.js";
import WPWMilsteinPage from "./views/WPWMilstein/WPWMilsteinPage";
import WPWMauricioPage from "./views/WPWMauricioPage/WPWMauricioPage";
import EsvPage from "./views/ESV/Esv";

import PrincipalPage from "./views/PrincipalPage/PrincipalPage";
import TVScorePage from "./views/TVScore/TVScorePage";
import WPWObliguitePage from "./views/ObliguiteWPW/WPWObliguitePage";




export const url_localisation = "/localisation";





ReactDOM.render(
    <BrowserRouter>
        <Analytics id="UA-182719684-1" debug>
            <Switch>
                <Route path="/error-page" component={ErrorPage} />
                <Route path="/simplewpw" component={WPWMilsteinPage} />
                <Route path="/complexwpw" component={WPWMauricioPage} />
                <Route path="/wpwoblique" component={WPWObliguitePage} />
                <Route path="/tvscore" component={TVScorePage} />
                <Route path="/esv" component={EsvPage} />
              <Route path="/" component={PrincipalPage} />
            </Switch>
        </Analytics>
    </BrowserRouter>,
  document.getElementById("root")
);






