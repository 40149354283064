import React, { useState} from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import {UnityComponent} from "./UnityComponent";
import {useStyles} from "./useStyle";


export function TwoChoices({question, reponseComponentTrue, reponseComponentFalse, title}){

    const classes = useStyles();

    const [choice, setChoice] = useState(null);

    function nextStep(){
        if(choice === "one"){
            return reponseComponentTrue;
        }else if(choice === "two"){
            return reponseComponentFalse
        }else{
            return null;
        }
    }

    return(
        <Grid container spacing={3} style={{marginTop:"10px", marginBottom:"10px"}} justify={"center"} xs={12}>

            <Grid container spacing={3} alignContent={"row"} style={{marginBottom:"10px"}} xs={12}>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        {title ? <h2>{title}</h2> : null}
                        <UnityComponent label={question} setFunction={(value) => setChoice(value.target.value)} value={choice}/>
                    </Paper>
                </Grid>
            </Grid>

            {nextStep()}

        </Grid>

    );



}

