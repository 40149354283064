/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Header from "components/Header/Header.js";
import Parallax from "components/Parallax/Parallax.js";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import presentationStyle from "assets/jss/material-kit-pro-react/views/presentationStyle.js";
import ICSHeaderLinks from "../../components/Header/ICSHeaderLinks";
import {ICSFooter} from "../../components/Footer/ICSFooter";
import {WPWMilstein} from "../../Tools/WPW_simple/WPWMilstein";
import Grid from "@material-ui/core/Grid";
import {WPWMauricio} from "../../Tools/WPWMauricio/WPWMauricio";
import {Card, CardHeader} from "@material-ui/core";
import {AxeVertical} from "../../Tools/ESV/AxeVertical";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import {Helmet} from "react-helmet";


const myStyle={
    root : { flex : 12}
}

const useStyles = makeStyles({...presentationStyle, ...myStyle});

export default function EsvPage() {
    React.useEffect(() => {
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
    });
    const classes = useStyles();
    return (
        <div>
            <Helmet>
                <title>Localisation ESV/TV idopathique</title>
                <meta name="description" content="Outils permettant de localiser les ESV ou TV sur coeur sain. Algorithme basé sur l'article de Brian P et al." />
                <meta name="keywords" content="localisation des ESV idiopathiques, extrasystole ventriculaire, localisation, ESV, localisation tachycardie ventriculaire idiopathique, tachycardie ventriculaire, TV" />
            </Helmet>
            <Header
                brand={"CardioTools"}
                links={<ICSHeaderLinks dropdownHoverColor="info" />}
                fixed
                color="transparent"
                changeColorOnScroll={{
                    height: 400,
                    color: "info"
                }}
            />
            <Parallax
                image={require("assets/img/slider-bienvenue_bleu.jpg")}
                className={classes.parallax}
                filter="dark"
                style={{height: 200}}
            >
                <div className={classes.container}>
                    <GridContainer>
                        <GridItem>
                            <div className={classes.brand}>
                                <Typography variant={"h2"} component={"h1"}>
                                    Localisation des ESV sur coeur sain
                                </Typography>
                            </div>
                        </GridItem>
                    </GridContainer>
                </div>
            </Parallax>


            <GridContainer justify="center" style={{justifyContent : "center"}}>
                <Grid container xs={10}>
                    <AxeVertical/>
                </Grid>

                <Grid container xs={9}>
                    <Card className={classes.root}>
                        <Grid container>
                            <CardContent className={classes.content}>
                                <Typography variant={"h5"} component="h2">
                                    Références :
                                </Typography>
                                <br/>
                                <a href="https://www.heartrhythmjournal.com/article/S1547-5271(19)30302-9/pdf" rel="noopener noreferrer" target="_blank" >
                                    <Typography className={classes.pos} color="textSecondary" component={"p"}>
                                        How to use the 12-lead ECG to predict the site of origin of idiopathic ventricular arrhythmias
                                    </Typography>
                                    <Typography variant="body2" component="p">
                                        Andres Enriquez, MD, Adrian Baranchuk, MD, David Briceno, MD, Luis Saenz, MD, Fermin Garcia, MD
                                    </Typography>
                                </a>
                                <br/>
                                <a href="https://www.jacc.org/doi/full/10.1016/j.jacc.2011.01.035" rel="noopener noreferrer" target="_blank">
                                    <Typography className={classes.pos} color="textSecondary" component={"p"}>
                                        The V2 Transition Ratio: A New Electrocardiographic Criterion for Distinguishing Left From Right Ventricular Outflow Tract Tachycardia Origin
                                    </Typography>
                                    <Typography variant="body2" component="p">
                                        Brian P. Betensky , Robert E. Park , Francis E. Marchlinski , Matthew D. Hutchinson , Fermin C. Garcia , Sanjay Dixit , David J. Callans , Joshua M. Cooper , Rupa Bala , David Lin , Michael P. Riley , and Edward P. Gerstenfeld
                                    </Typography>
                                </a>
                            </CardContent>


                        </Grid>
                    </Card>
                </Grid>

            </GridContainer>

            <ICSFooter/>
        </div>
    );
}
