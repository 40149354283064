/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Header from "components/Header/Header.js";
import Parallax from "components/Parallax/Parallax.js";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import presentationStyle from "assets/jss/material-kit-pro-react/views/presentationStyle.js";
import ICSHeaderLinks from "../../components/Header/ICSHeaderLinks";
import {WPWMilstein} from "../../Tools/WPW_simple/WPWMilstein";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {Helmet} from "react-helmet";
import {Card} from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import {ICSFooter} from "../../components/Footer/ICSFooter";




const useStyles = makeStyles(presentationStyle);

export default function WPWMilsteinPage() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  return (
    <div>

        <Helmet>
            <title>Localisation WPW (by MILSTEIN et al.)</title>
            <meta name="description" content="Outils permettant de localiser une voie accessoire à partir de l'ECG. Algorithme basé sur l'article de MILSTEIN S et al." />
            <meta name="keywords" content="WPW localisation, WPW, wolf-parkinson-white, voie assessoire" />
        </Helmet>

        <Header
            brand={"EPTools"}
            links={<ICSHeaderLinks dropdownHoverColor="info" />}
            fixed
            color="transparent"
            changeColorOnScroll={{
                height: 400,
                color: "info"
            }}
        />
      <Parallax
        image={require("assets/img/ESV.png")}
        className={classes.parallax}
        filter="dark"
      >
        <div className={classes.container}>
          <GridContainer>
            <GridItem>
              <div className={classes.brand}>
                  <Typography variant={"h2"} component={"h1"}>
                  WPW Localisation
                  </Typography>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>


        <GridContainer justify="center" style={{justifyContent : "center", marginTop:"10px", marginBottom: "10px"}}>
            <Grid container xs={10}>
                <WPWMilstein/>
            </Grid>


        <Grid container xs={9}>
            <Card className={classes.root}>
                <Grid container>


                    <CardContent className={classes.content} >
                        <Typography variant={"h5"} component="h2">
                            Référence :
                        </Typography>
                        <a href="https://pubmed.ncbi.nlm.nih.gov/2440006/" target="_blank" rel="noopener noreferrer">
                            <Typography className={classes.pos} color="textSecondary" component={"p"}>
                                An algorithm for the electrocardiographic localization of accessory pathways in Wolff-Parkinson-White syndrome
                            </Typography>
                            <Typography variant="body2" component="p">
                                MILSTEIN S, SHARMA AD, GUIRAUDON GM and KLEIN GJ
                            </Typography>
                        </a>
                    </CardContent>


                </Grid>
            </Card>
        </Grid>
    </GridContainer>

        <ICSFooter/>

     </div>
  );
}
