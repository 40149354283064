import React from "react";
import Footer from "components/Footer/Footer.js";


import GridItem from "../Grid/GridItem";
import GridContainer from "../Grid/GridContainer";





export function ICSFooter(){


    return(
        <Footer
            theme="dark"
            content={
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <h5>A propos de de CardioTools</h5>
                        <p>
                            L'objectif de ce site est de numériser certains algorithmes et outils utiles à la pratique des cardiologues.
                        </p>
                           <p>
                               Ce site n'est qu'à ses débuts. Nous avons besoin de vous ! A vous de nous dire quels outils, quels algorithmes vous souhaitez voir numérisés afin de faciliter votre pratique quotidienne.

                           </p>
                            <p>
                                N'hésitez pas à nous envoyer un mail avec vos demandes à l'adresse suivante <a href="mailto:cardiotools@gmail.com">cardiotools@gmail.com</a>

                            </p>

                    </GridItem>
                </GridContainer>
            }
        />
    )
}

