import React, {useState} from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import {UnityComponent} from "../../UnityComponent";
import {useStyles} from "../style/useStyle";
import {LBBB} from "./Step3/LBBB";
import {RBBB} from "./Step3/RBBB";




export function AxeSuperieur(){

    const classes = useStyles();

    const [RBBBvalue, setRBBBvalue] = useState(null);

    function stepMore(){
        if(RBBBvalue === "one"){
            return <RBBB/>
        }else if(RBBBvalue === "two"){
            return <LBBB/>
        }
    }

    return(


        <Grid container spacing={3} justify={"center"} xs={12} style={{marginBottom:"10px"}}>
            <Grid item xs={12}>
                <Paper className={classes.paper}>
                    <UnityComponent label={"RBBB"} setFunction={(value) => setRBBBvalue(value.target.value)} value={RBBBvalue}/>
                </Paper>
            </Grid>

            {stepMore()}

        </Grid>


    );

}

