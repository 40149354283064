import React from 'react';
import {TwoChoices} from "../TwoChoices";
import {ResponseWPW as Response} from "../ResponseWPW";


function ReferenceDiv(){
    return <div>Reference : <a target="_blank" rel="noopener noreferrer" href={"https://onlinelibrary.wiley.com/doi/epdf/10.1111/j.1540-8159.1987.tb04520.x"}>Milstein S, Sharma AD, Guiraudon GM, and Klein GJ. An Algorithm for the Electrocardiographic Localization of Accessory Pathways in the Wolff-Parkinson-White Syndrome. Pacing Clin Electrophysiol 1987 May, 10(3 Pt 1) 555-63</a></div>
}
function RSenV1(){

    return <TwoChoices
        question={"rS en V1, V2, V3"}
        reponseComponentFalse={<Response text={"Posteroseptal"} referenceDiv={<ReferenceDiv/>}/>}
        reponseComponentTrue={<Response text={"Right free wall"} referenceDiv={<ReferenceDiv/>}/>}
    />
}

function AxeQrsSup30(){

    return <TwoChoices
        question={"QRS axis > +30"}
        reponseComponentFalse={<Response text={"Right free wall"} referenceDiv={<ReferenceDiv/>}/>}
        reponseComponentTrue={<Response text={"Right anteroseptal"} referenceDiv={<ReferenceDiv/>}/>}
    />
}

function RSenV12(){

    return <TwoChoices
        question={"RS  or rS in V1 or V2"}
        reponseComponentFalse={<Response text={"Undetermined"} referenceDiv={<ReferenceDiv/>}/>}
        reponseComponentTrue={<Response text={"Left free wall"} referenceDiv={<ReferenceDiv/>}/>}
    />
}

function BBG2(){
    return <TwoChoices
        question={"Left bundle branch block"}
        reponseComponentFalse={<RSenV12/>}
        reponseComponentTrue={<AxeQrsSup30/>}
    />
}

function NegOuIsoEnD2(){
    return <TwoChoices
        question={"Q or isoelectric ∆ i two of II, III, aVF"}
        reponseComponentFalse={<BBG2/>}
        reponseComponentTrue={<RSenV1/>}
    />
}



function BBG(){
    return <TwoChoices
        question={"Left bundle branch block"}
        reponseComponentTrue={<Response text={"Right anteroseptal"} referenceDiv={<ReferenceDiv/>}/>}
        reponseComponentFalse={<Response text={"Left free wall"} referenceDiv={<ReferenceDiv/>}/>}
    />
}



export function WPWMilstein(){

    return <TwoChoices
        question={"Q or isoelectric ∆ in leads I, aVL or V6"}
        reponseComponentFalse={<NegOuIsoEnD2/>}
        reponseComponentTrue={<BBG/>}
        />
}




